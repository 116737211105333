export default {
  language: 'Bahasa',
  appName: 'InKanteen',
  greeting: 'Halo Dunia!',
  hello: 'Halo',
  location: 'Jakarta',
  home: 'Beranda',
  tenants: 'Tenant',
  menus: 'Menu',
  tenantNear: 'Tenant Terdekat',
  category: 'Kategori',
  order: 'Pesanan',
  lastOrdered: 'Terakhir Dipesan',
  orderHistory: 'Riwayat Pesanan',
  recommendationForYou: 'Rekomendasi untukmu!',
  fromTenant: 'Dari tenant ini',
  seeAll: 'Lihat Semua',
  seeLess: 'Lebih Sedikit',
  seeHistory: 'Lihat Riwayat',
  seeTenant: 'Lihat Tenant',
  detail: 'Detail',
  seeDetail: 'Lihat Detail',
  payNow: 'Bayar Sekarang',
  changeMethod: 'Ubah Metode',
  changePaymentMethod: 'Ubah Metode Pembayaran',
  payOrder: 'Bayar Pesanan',
  seeHowToPay: 'Lihat Cara Bayar',
  sale: 'Promo',
  cart: 'Keranjang',
  wallet: 'Dompet',
  points: 'Poin',
  totalPoints: '%{total} Poin',
  filter: 'Filter',
  sort: 'Urutkan',
  price: 'Harga',
  minimal: 'Minimal',
  maximal: 'Maksimal',
  account: 'Akun',
  scanQR: 'Scan QR',
  scanAQRCode: 'Scan a QR Code',
  product: 'Produk',
  productCategory: 'Kategori Produk',
  productRecommendation: 'Rekomendasi Produk',
  notification: 'Notifikasi',
  products: 'produk',
  changeLanguage: 'Ganti bahasa',
  balance: 'Saldo',
  balanceAfter: 'Saldo setelah transaksi',
  topup: 'Top up',
  pendingTopup: 'Top up Tertunda',
  myPoints: 'Poinku',
  myOrder: 'Pesanan kamu',
  inviteFriends: 'Undang teman',
  termsAndConditions: 'Syarat dan ketentuan',
  privacyPolicy: 'Kebijakan privasi',
  logout: 'Keluar',
  email: 'Alamat Email',
  password: 'Password',
  passwordConfirmation: 'Konfirmasi Password',
  forgotPassword: 'Lupa password?',
  forgotPIN: 'Lupa PIN Kamu?',
  resetPIN: 'Reset PIN',
  forgotPasswordDesc:
    'Silahkan isi form di bawah dengan nomor WhatsApp yang kamu gunakan untuk mendaftar. Kamu akan dikirimi nomor WhatsApp berisi petunjuk tentang cara mereset PIN kamu.',
  send: 'Kirim',
  submit: 'Submit',
  sendEmail: 'Kirim Email',
  sendOTP: 'Kirim OTP',
  resetPassword: 'Reset Password',
  resetPasswordDesc:
    'Permintaan reset password berhasil, silakan buat password baru untuk akun ',
  newPassword: 'Password Baru',
  newPasswordConfirmation: 'Konfirmasi Password Baru',
  verificationCheckEmail:
    'Silahkan cek email kamu untuk mengetahui kode verifikasi',
  loginEng: 'Login',
  login: 'Masuk',
  save: 'Simpan',
  welcome: 'Selamat Datang',
  welcomeTo: 'Selamat Datang di',
  asCustomer: 'Sebagai Customer',
  asTenant: 'Sebagai Tenant',
  back: 'Kembali',
  wantToGetPromo: 'Ingin dapat promo?',
  wrong_email_or_password: 'Email atau password salah',
  wrong_phone_or_pin: 'Nomor WhatsApp atau PIN salah',
  phone_number_not_valid:
    'Nomor WhatsApp tidak valid, mohon diawali dengan kode negara dengan minimal 10 digit',
  phone_number_required: 'Nomor WhatsApp harus diisi',
  information: 'Informasi',
  you_are_not_logged_in: 'Kamu belum login',
  register_success: 'Request pendaftaran kamu berhasil',
  you_have_successfully_registered: 'Kamu berhasil terdaftar',
  registration_failed: 'Registrasi user gagal',
  you_have_successfully_logged_in: 'Kamu berhasil login',
  error_send_review: 'Review gagal dikirim',
  enter_review: 'Mohon masukkan review kamu',
  enter_email: 'Mohon masukkan email kamu',
  enter_password: 'Mohon masukkan password kamu',
  enter_passwordConfirmation: 'Mohon masukkan konfirmasi password kamu',
  enter_phoneNumber: 'Nomor WhatsApp',
  enter_ewalletNumber: 'Nomor ewallet kamu',
  pleaseEnter_ewalletNumber: 'Mohon masukkan nomor ewallet kamu',
  enter_verificationCode: 'Mohon masukkan kode verifikasi kamu',
  enter_name: 'Mohon masukkan nama kamu',
  enter_yourname: 'Masukkan nama kamu',
  enter_year: 'Mohon pilih tahun lahir',
  enter_month: 'Mohon pilih bulan lahir',
  enter_date: 'Mohon pilih tanggal lahir',
  enter_educationstatus: 'Mohon pilih status pendidikan',
  enter_faculty: 'Mohon pilih fakultas',
  enter_major: 'Mohon pilih jurusan',
  enter_classyear: 'Mohon pilih tahun angkatan',
  minName: 'Minimal 3 karakter ya',
  maxName: 'Masukkan nama kamu kurang dari 30 karakter aja ya',
  forbiddenName: 'Mohon isi nama kamu dengan benar ya',
  enterRealName:
    'Harap masukan namamu untuk melanjutkan proses pemesanan selanjutnya',
  yourOrderName: 'Pesananmu atas nama siapa',
  success_update_profile: 'Berhasil mengubah profil',
  failed_update_profile: 'Gagal mengubah profil',
  valid_email_address: 'Mohon masukkan alamat email yang valid',
  valid_phone_number: 'Mohon masukkan nomor WhatsApp yang valid',
  valid_ewallet_number: 'Mohon masukkan nomor ewallet yang valid',
  valid_qty: 'Mohon masukkan qty yang valid',
  email_sent: 'Email Terkirim',
  otp_sent: 'OTP telah dikirim',
  account_verified: 'Akun Terverifikasi',
  verify_code_true: 'Kode verifikasi benar',
  verify_code_digit: 'Kode verifikasi harus 4 digit',
  pin_code_digit: 'Pin harus 6 digit',
  field_must_be_alphabet: 'hanya diperbolehkan huruf saja',
  field_must_be_alphanumeric: 'hanya diperbolehkan huruf dan angka saja',
  field_must_be_numeric: 'hanya diperbolehkan angka saja',
  password_only_allowed_alphanumeric_and_special_characters:
    'Password hanya diperbolehkan huruf, angka dan spesial karakter',
  confirmation: 'Konfirmasi',
  are_you_sure_want_to_logout: 'Apakah kamu yakin ingin keluar?',
  yes: 'Ya',
  no: 'Tidak',
  isHelpful: 'Apakah ini membantu?',
  find_food_or_drink: 'Cari makanan & minuman',
  data_not_found: 'Data tidak ditemukan',
  tab_pending: 'Tertunda',
  tab_on_process: 'Diproses',
  tab_done: 'Selesai',
  tab_cancelled: 'Dibatalkan',
  loginDesc: 'Masukkan e-mail dan passwordmu',
  noAccount: 'Belum punya akun?',
  joinUs: 'Yuk daftar',
  register: 'Register',
  name: 'Nama',
  selectCountry: 'Pilih kode negara',
  phoneNumber: 'Nomor WhatsApp',
  insertPhoneNumber: 'Masukkan nomor WhatsApp kamu',
  insertDescription: 'Masukkan deskripsi',
  minimumPhoneNumber: 'Masukkan minimal 9 digit',
  startWithPhoneNumber: 'Harus diawali angka 8',
  cannotSamePhoneNumber: 'Nomor WhatsApp tidak boleh sama dengan sebelumnya',
  onlyNumber: 'Mohon hanya masukkan angka',
  insertPhoneNumberEwallet: 'Masukkan nomor WhatsApp ewallet',
  verificationCode: 'Kode Verifikasi',
  registerAggreement: 'Dengan mendaftar, kamu sudah membaca dan setuju dengan',
  registerAggreementAlt:
    'Nomor whatsapp kamu dibutuhkan untuk mengetahui status pesanan, menerima bukti pesanan, dan menerima promo-promo menarik dari InKanteen',
  termsOfUse: 'Terms of Use',
  and: 'dan',
  next: 'Lanjut',
  haveAccount: 'Sudah punya akun?',
  accountVerification: 'Verifikasi Akun',
  verification: 'Verifikasi',
  enterNewPin: 'Masukkan PIN baru kamu',
  confirmNewPin: 'Konfirmasi PIN baru kamu',
  enterPin: 'Masukkan PIN kamu',
  resendCode: 'Kirim ulang kode',
  reloadCode: 'Muat ulang kode',
  resendCodeWA: 'Request Lewat WhatsApp',
  institution: 'Instansi',
  yourInstitution: 'Instansi Kamu',
  idNumber: 'Nomor Induk',
  enter_idNumber: 'Mohon masukkan Nomor Induk yang valid',
  finish: 'Selesai',
  orderNow: 'Pesan sekarang',
  continueShopping: 'Lanjut belanja',
  cartEmptyNotice: 'Keranjang belanjamu masih kosong',
  cartEmptyNoticeDetail:
    'Sepertinya kamu belum memesan apa-apa nih. Yuk, segera pilih pesananmu!',
  addToCart: 'Masukkan keranjang',
  add: 'Tambah',
  showResult: 'Tampilkan Hasil',
  reset: 'Reset',
  total: 'Total',
  with_fee: '(+fee)',
  point_gained: 'Poin yang diterima',
  youWillGet: 'Kamu mendapatkan',
  subTotal: 'Subtotal',
  discount: 'Diskon',
  unique_code: 'Kode Unik',
  point_reward: 'Poin yang didapatkan',
  promo: 'Promo',
  usePromo: 'Pakai promo aja biar lebih hemat!',
  detailPromo: 'Detail promo',
  usingPromo: 'Pakai Promo',
  usingPromoLogin: 'Login untuk pakai promo',
  youCanSave: 'Kamu bisa hemat',
  youCanGetCashback: 'Kamu bisa mendapatkan',
  enterPromoCode: 'Masukkan kode promo',
  promoEnd: 'Berakhir pada',
  promoValidUntil: 'Valid sampai ',
  promoDay: 'hari lagi',
  promoDayAgo: 'hari lalu',
  showDetail: 'Lihat detail',
  applicableArea: 'Daerah yang berlaku',
  promoUsed: '1 promo terpakai',
  use: 'Terapkan',
  promoCodeEmpty: 'Mohon masukkan kode promo terlebih dahulu',
  pleaseChoosePromo: 'Silakan pilih promo yang aktif terlebih dahulu',
  checkout: 'Checkout',
  delete: 'Hapus',
  chooseAll: 'Pilih Semua',
  addNote: 'Tambahkan Catatan',
  note: 'Catatan',
  edit: 'Ubah',
  foods: 'Makanan',
  drinks: 'Minuman',
  search_tenant: 'Cari Tenant',
  search_menu: 'Cari Menu',
  search: 'Cari',
  success: 'Berhasil',
  successfully_added_to_cart: 'Pesanan telah masuk keranjang!',
  failed_add_to_cart: 'Gagal menambahkan ke keranjang',
  successfully_add_qty: 'Berhasil menambahkan qty',
  successfully_sub_qty: 'Berhasil mengurangi qty',
  successfully_delete_from_cart: 'Berhasil menghapus dari keranjang',
  success_change_note: 'Berhasil mengubah notes',
  success_get_data: 'Berhasil meload data',
  failed: 'Gagal',
  failed_get_data: 'Data gagal diload',
  description: 'Deskripsi',
  detail_product: 'Detail Produk',
  process_time: 'Waktu Proses',
  availability: 'Ketersediaan',
  in_stock: 'Stok tersedia',
  out_stock: 'Stok habis',
  product_not_found: 'Detail produk tidak ditemukan',
  promo_not_found: 'Promo tidak ditemukan',
  promo_detail_not_found: 'Detail promo tidak ditemukan',
  serving_for: 'Pesan untuk :',
  serving_for_later: 'Pesan untuk nanti',
  oclock: 'Pukul',
  select_date: 'Pilih tanggal',
  select_hour: 'Pilih jam',
  select_minute: 'Pilih menit',
  now: 'Sekarang',
  process_payment: 'Ke Pembayaran',
  process_payment_confirmation: 'Konfirmasi Pembayaran',
  paymentSummary: 'Ringkasan pembayaran',
  pay: 'Bayar',
  with_cash: 'dengan cash',
  no_data: 'Tidak ada data',
  no_more_data: 'Tidak ada lagi data yang dapat ditampilkan',
  no_result: 'Tidak ada hasil yang dapat ditampilkan',
  no_photo_available: 'Tidak ada foto yang tersedia',
  no_product_available: 'Tidak ada produk yang tersedia',
  order_success: 'Order Sukses!',
  order_success_desc:
    'Terima kasih! Pesananmu akan segera diproses oleh merchant',
  order_cancelled: 'Pesanan Gagal',
  order_cancelled_system: 'Waktu Habis',
  order_cancelled_other: 'Pesanan Dibatalkan',
  order_cancelled_system_desc: 'Silakan mengubah metode pembayaran',
  order_cancelled_other_desc:
    'Silakan memesan ulang dengan cara klik tombol di bawah',
  order_pending: 'Pesanan Pending',
  status_pending: 'Pending',
  order_status: 'Pesanan kamu berstatus ',
  order_pending_desc: 'Segera selesaikan pembayaran',
  order_pending_cash_desc:
    'Silakan menuju kasir untuk menyelesaikan pembayaran kamu',
  order_pending_brizzi_desc:
    'Silakan menuju merchant untuk menyelesaikan pembayaran kamu',
  order_pending_subdesc: 'Nominal pembayaran harus sesuai hingga ',
  order_pending_cash_subdesc: 'Harap siapkan uang pas',
  order_pending_brizzi_subdesc: 'Make sure your BRIZZI balance is sufficient',
  order_times_up: 'Waktu Habis',
  last_3_digit: 'tiga digit terakhir',
  already_paid: 'Saya sudah bayar',
  back_to_home: 'Kembali ke Beranda',
  back_to_menu: 'Ke Halaman Menu',
  estimated_time: 'Estimasi waktu',
  minute: 'Menit',
  openModal: 'Buka Modal',
  track_your_order: 'Lacak pesananmu',
  track_my_order: 'Lacak pesananku',
  have_you_order_yet: 'Sudah pesan belum?',
  have_not_order_pending_description:
    'Tidak ada pesanan yang tertunda di sini. Kamu dapat membuat pesanan baru dengan mengklik tombol di bawah ini.',
  order_no_item: 'Tidak ada pesanan',
  order_no_pending: 'Tidak ada pesananmu yang\npending di sini',
  order_no_process: 'Tidak ada pesananmu yang\nsedang diproses di sini',
  order_no_done: 'Tidak ada pesananmu yang\nsudah selesai di sini',
  order_no_cancelled: 'Tidak ada pesananmu yang\ndibatalkan di sini',
  your_order: 'Pesanan Kamu',
  id_order: 'ID order',
  order_tab_pending: 'Pending',
  order_tab_onprocess: 'Diproses',
  order_tab_done: 'Selesai',
  order_tab_cancelled: 'Dibatalkan',
  tracking_cancelled: 'Dibatalkan',
  other_item: 'item lainnya',
  order_waiting_confirmation: 'Menunggu Konfirmasi',
  order_ready: 'Pesanan Siap',
  order_complete_payment: 'Selesaikan Pembayaran',
  order_reorder_btn: 'Beli Lagi',
  order_download_receipt_btn: 'Unduh Receipt',
  successfully_download_receipt: 'Berhasil mengirim E-receipt ke WhatsApp',
  wait_download_receipt:
    'Tunggu sebentar. Kita akan kirim E-receipt ke WhatsApp kamu',
  failed_download_receipt: 'Gagal mengirim E-receipt ke WhatsApp',
  receipt_downloaded: 'Berhasil Mengunduh E-receipt',
  receipt_download_failed: 'Gagal Mengunduh E-receipt',
  transaction_not_done: 'Transaksi anda belum selesai',
  ereceipt_is_for_done_only:
    'E-receipt hanya tersedia untuk transaksi yang telah selesai',
  order_ready_btn: 'Sudah Siap',
  order_onprocess_btn: 'Sedang Diproses',
  order_onprocess_desc: 'Estimasi',
  time_hour: 'Jam',
  time_minute: 'Menit',
  order_cancel_btn: 'Batalkan',
  order_cancel_title: 'Batalkan pesanan',
  order_cancel_all: 'Batalkan semua pesanan',
  order_cancel_no_item_selected:
    'Silakan pilih setidaknya satu item untuk membatalkan pesanan',
  order_cancel_desc: 'Kamu yakin mau batalkan pesanan ini?',
  order_cancel_success: 'Pesanan berhasil dibatalkan',
  order_cancel_failed: 'Pesanan gagal dibatalkan',
  order_review_success: 'Pesanan berhasil diulas',
  order_review_failed: 'Pesanan gagal diulas',
  order_confirm: 'Pesanan Dikonfirmasi',
  order_confirmed: 'Pesanan Dikonfirmasi',
  review_appbar: 'Beri ulasan',
  review_only_shown_by: 'Ulasan hanya bisa dilihat oleh pihak tenant',
  give_rating: 'Beri penilaian',
  et_rating: 'Mohon beri penilaian terlebih dahulu',
  write_review: 'Beri ulasan',
  write_review_here: 'Beri ulasan di sini',
  add_photo: 'Upload foto barang',
  gallery: 'Galeri',
  camera: 'Kamera',
  track_appbar: 'Lacak Pesananmu',
  tracking_confirmed: 'Pesanan dikonfirmasi',
  tracking_on_progress: 'Pesanan diproses',
  tracking_almost_done: 'Hampir selesai',
  tracking_ready: 'Pesanan siap diambil',
  tracking_done: 'Selesai',
  tracking_completed: 'Selesai',
  tracking_canceled: 'Pesanan dibatalkan',
  tracking_status_confirmed: 'Dikonfirmasi',
  tracking_status_on_progress: 'Diproses',
  tracking_status_completed: 'Selesai',
  tracking_status_cancelled: 'Dibatalkan',
  tracking_waiting_confirmation_title: 'Menunggu konfirmasi penjual',
  tracking_waiting_confirmation_desc: 'Akan dikonfirmasi sebelum pukul',
  tracking_complete_your_payment: 'Selesaikan pembayaranmu',
  tracking_complete_your_payment_desc:
    'Pembayaranmu akan dikonfirmasi sebelum pukul',
  tracking_on_progress_title: 'Estimasi pesanan selesai',
  tracking_on_progress_desc: '@time1 - @time2',
  tracking_ready_title: 'Pesananmu sudah siap!',
  tracking_ready_desc: 'Kamu bisa mengambil pesananmu sekarang',
  tracking_done_title: 'Pesanan selesai',
  tracking_done_desc: 'Terima kasih, jajan lagi ya!',
  tracking_review: 'Beri ulasan',
  tracking_reviewed: 'Sudah diberi ulasan',
  tracking_order_details: 'Detail Pesanan',
  pickup_or_delivered: 'Ambil sendiri atau diantar',
  pickup: 'Ambil sendiri',
  ready_delivered: 'Siap Diantar',
  ready_to_pickup: 'Siap Diambil',
  delivered: 'Diantar',
  to_pickup: 'Diambil',
  delivery_label: 'Diantar ke Meja',
  pickup_label: 'Ambil Sendiri',
  delivery_label_desc: 'Pesananmu akan kami antar',
  pickup_label_desc: 'Silakan ambil sendiri pesananmu di tenant',
  delivery_desc: 'Tenant ini menggunakan sistem Diantar ke Meja',
  pickup_desc: 'Tenant ini menggunakan sistem Ambil Sendiri',
  delivery_info: 'Diantar ke Meja',
  pickup_info: 'Ambil Sendiri',
  update: 'Perbarui',
  update_order_status: 'Perbarui status pesanan',
  order_status_updated: 'Status pesanan berhasil diperbarui',
  select_all: 'Pilih semua',
  all_status: 'Semua status',
  promo_available: 'Promo tersedia',
  successfully_cancelled: 'Berhasil dibatalkan!',
  added_to_cart: 'Pesanan ditambahkan ke keranjang!',
  table_row_changed: 'Nomor meja berhasil diubah',
  table_row_failed: 'Nomor meja gagal diubah',
  failed_fetch_data: 'Gagal ambil data',
  cant_use_promo_code: 'Kode promo tidak berlaku',
  review_thanks: 'Terima kasih reviewnya, jajan lagi yaa...',
  review_failed: 'Gagal memberikan review',
  waiting_update_status: 'Menunggu update status',
  total_order: 'Total Pesanan',
  total_payment: 'Total bayar',
  round_price: 'Pembulatan',
  waiting_for_payment: 'Pembayaran akan dibatalkan dalam',
  newest: 'Terbaru',
  payment: 'Pembayaran',
  qris: 'QRIS',
  platform_fee: 'Platform fee',
  tax_fee: 'Tax Fee',
  service_fee: 'Service Fee',
  service_and_tax_fee: 'Service dan Tax Fee',
  serviceOtherFee: 'Biaya layanan',
  serviceOtherFeeDesc:
    'Service fee, tax fee, platform fee, kode unik/pembulatan, dsb',
  mdr_fee: 'MDR Fee',
  reason_cancellation: 'Alasan pembatalan',
  table_row: 'Nomor meja',
  edit_table_row: 'Ubah nomor meja',
  insert_table_row: 'Masukkan nomor meja',
  want_to_change_table_row: 'Ingin mengubah nomor meja?',
  where_you_sitting: 'Di mana kamu duduk?',
  input_table_row:
    'Input nomor meja di mana kamu duduk, agar tenant lebih mudah mengantarkan pesanan kamu',
  set_table: 'Set nomor meja',
  payment_method: 'Metode pembayaran',
  select_payment_method: 'Pilih Metode Pembayaran',
  select: 'Pilih',
  qris_scan: 'Scan QRIS',
  ewallet: 'E-wallet',
  bankAccount: 'Akun Bank',
  va: 'Transfer Virtual Account',
  verification_code_desc:
    'Masukkan kode OTP yang dikirim melalui WhatsApp ke nomor',
  register_desc:
    'Halo, sebelum melanjutkan, mohon input nomor WhatsApp kamu di bawah ini untuk mendaftar',
  login_desc:
    'Halo, sebelum melanjutkan, mohon input nomor WhatsApp kamu di bawah ini untuk login',
  login_or_register_desc:
    'Halo, sebelum melanjutkan, mohon input nomor WhatsApp kamu di bawah ini untuk login atau mendaftar',
  error_resend_otp: 'Silakan tunggu sebelum bisa mengirim kembali OTP',
  transaction_pending: 'Mohon selesaikan pembayaran terlebih dahulu',
  transaction_success: 'Transaksi berhasil',
  enter_new_pin: 'Masukan PIN baru kamu',
  enter_pin: 'Masukan PIN kamu',
  confirm_new_pin: 'Konfirmasi PIN baru kamu',
  confirm_pin_not_match: 'Konfirmasi PIN tidak sama',
  enter_table_row: 'Nomor meja wajib diisi',
  payment_method_required: 'Pilih metode pembayaran terlebih dahulu',
  edit_account: 'Edit Akun',
  edit_phone: 'Edit Nomor WhatsApp',
  transfer_to: 'Transfer ke',
  update_profile: 'Update Akun',
  dine_in: 'Dine In',
  take_away: 'Take Away',
  please_rescan_qr: 'InstituteId kadaluwarsa, silakan scan ulang QR Code',
  tenant_closed: 'Tenant sedang tutup',
  sorry: 'Mohon maaf...',
  under_maintenance_sorry: 'Under Maintenance',
  service_closed:
    'Untuk saat ini tidak melayani pemesanan melalui inkanteen 🙏🏻',
  service_maintenance: 'Mohon maaf, kami sedang dalam pembaruan sistem :(',
  please_wait: 'Ditunggu sebentar, ya!',
  under_maintenance: 'Website saat ini sedang dalam proses perbaikan',
  under_maintenance_thankyou:
    'Terima kasih atas pengertiannya, sampai jumpa kembali',
  order_type: 'Tipe Order',
  please_select_valid_date:
    'Mohon pilih tanggal yang valid sampai 30 hari ke depan',
  please_select_valid_hour: 'Mohon pilih jam yang valid',
  please_select_valid_minute: 'Mohon pilih menit yang valid',
  additional_menu: 'Tambahkan Menu',
  addon_menu: 'Menu tambahan:',
  add_another_items: 'Tambahkan items lainnya',
  required: 'Required',
  cash: 'Cash',
  cash_payment_info:
    '*Pembayaran menggunakan cash hanya tersedia untuk pemesanan secara langsung dan dalam satu tenant saja',
  brizzi_payment_info:
    '*Pembayaran menggunakan BRIZZI hanya tersedia untuk pemesanan secara langsung dan dalam satu tenant saja',
  copy: 'Salin',
  copied: 'Disalin',
  copy_to_clipboard: 'Salin ke papan klip',
  copy_to_clipboard_success: 'disalin ke papan klip',
  copy_to_clipboard_failed: 'Gagal menyalin ke papan klip',
  please_select_institute: 'Pilih restoran atau institusi terlebih dahulu',
  landing_desc:
    'Halo, sebelum melanjutkan, mohon pilih restoran atau institusi di bawah ini',
  coming_soon: 'Coming soon.. 🙏🏻',
  pageNotFound: 'Halaman tidak ditemukan',
  pageNotFoundDesc: 'Oops, sepertinya halaman yang kamu cari tidak ada di sini',
  pageNotFoundSubDesc: '(error 404)',
  pleaseChooseItem: 'Silakan pilih menu sebelum checkout',
  maxCartNotes: 'Notes tidak boleh melebihi 255 karakter',
  item: 'item',
  totalItem: '%{total} Item',
  totalOrder: '%{total} Pesanan',
  qty: 'Qty',
  more: 'More',
  oneMoreStep: 'Satu langkah lagi',
  resendCodeIn: 'Kode dapat dikirim ulang dalam ',
  resendLinkIn: 'Link dapat dikirim ulang dalam ',
  second: 'detik',
  tenantClosed:
    'Maaf, toko kami sedang tidak melayani pemesanan lewat aplikasi. Silakan pesan langsung.',
  closed: 'Tutup',
  selectOne: 'Select one',
  cantRemoveValue: "Can't remove this value",
  yourProfile: 'Profile Anda',
  tab_your_order: 'Pesanan Anda',
  tab_order_history: 'History Pesanan',
  tap_to_see_history: 'Ketuk untuk melihat history',
  profileSettings: 'Pengaturan Profil',
  refund: 'Refund',
  refundAmount: 'Nominal',
  refundMethod: 'Metode refund',
  refundMinimal: 'Minimal refund',
  refundSummary: 'Refund summary',
  refundTotal: 'Refund total',
  pleaseLoginBeforeRefund: 'Untuk refund kamu harus login dulu',
  administrationFee: 'Administration fee',
  moneyTransfered: 'Money transferred',
  addNewMethod: 'Add New Method',
  accountName: 'Nama Akun',
  ewalletType: 'Pilih E-wallet',
  bankName: 'Nama Bank',
  bankAccountNumber: 'Nomor Rekening Bank',
  ewalletNumber: 'Nomor E-wallet',
  refundHistory: 'Riwayat Refund',
  noRefundHistory: 'Tidak ada riwayat refund',
  refundHistoryProcess: 'Sedang diproses',
  excludeAdminFee: '(exclude admin fee)',
  sendVerificationCode: 'Kirim kode verfikasi',
  pleaseInputDataFirst: 'Mohon input data terlebih dahulu',
  pleaseVerifyOTPFirst: 'Verifikasi OTP terlebih dahulu',
  refundProcessSuccess: 'Sukses mengajukan refund',
  refundSuccess: 'Pengajuan refund berhasil',
  refundSuccessDesc: 'InKanteen akan segera memproses pengajuan refund kamu.',
  refundSuccessSubDesc:
    'Uang refund akan ditarik sementara dari saldo. Saldo kamu berkurang',
  forProcess: 'untuk diproses',
  promotion: 'Promo',
  successUsePromo: 'Promo berhasil diterapkan',
  successUsePromoNominal: 'Kamu mendapat potongan sebesar %{value}',
  successUsePromoCashbackNominal: 'Kamu mendapat cashback sebesar %{value}',
  cashback: 'Cashback',
  clickOrScanQR: 'SCREENSHOT ATAU SCAN QR',
  downloadQR: 'Download QR',
  extra: 'Extra',
  addMoreOrder: 'Tambah pesanan lagi',
  havePendingRefund: 'Kamu masih punya refund dalam proses!',
  important: 'PENTING!',
  importantPayment:
    'Pastikan untuk membayar dengan nominal tepat (termasuk 3 digit terakhir)',
  inputGroupDesc:
    'Sebelum lanjut, isi nama sama kelas/jurusan kamu dulu, ya! Sekali ini aja kok.',
  inputUserCollegeDesc:
    'Sebelum lanjut, isi beberapa data di bawah dulu, ya! Sekali ini aja kok.',
  inputNameDesc:
    'Sebelum lanjut, isi namamu untuk memudahkan kami saat pengambilan.',
  groupInput: 'Kelas/Jurusan',
  groupPlaceholder: 'Contoh: A05',
  enterGroup: 'Mohon masukkan kelas/jurusan kamu',
  minClass: 'Minimal 2 karakter ya',
  maxClass: 'Masukkan nama kelas/jurusan kamu kurang dari 15 karakter aja ya',
  autoCancelPayment: 'Pembayaran akan dibatalkan dalam ',
  cannotOnlySpace: 'Field ini tidak boleh hanya berisi spasi atau tab',
  searchResult: 'Hasil pencarian untuk',
  searchResultEmpty: 'Tidak ditemukan hasil pencarian untuk',
  needWaiter: 'Butuh waiter?',
  callWaiter: 'Panggil waiter',
  callWaiterDesc:
    'Butuh bantuan waiter? Tulis kebutuhanmu dan waiter akan segera datang ke mejamu.',
  doYouNeedWaiter: 'Lagi butuh waiter?',
  waiterWillComeSoon: 'Waiter akan segera datang',
  cleaning: 'Cleaning',
  ashtray: 'Asbak',
  other: 'Lainnya',
  yourNeed: 'Kebutuhanmu',
  insertYourNeed: 'Tuliskan kebutuhanmu',
  chooseYourNeed: 'Pilih kebutuhanmu terlebih dahulu',
  cancelled_by_tenant: 'Dibatalkan oleh tenant',
  cancelled_by_user: 'Dibatalkan oleh user',
  cancelled_by_system: 'Dibatalkan oleh sistem',
  orReturn: 'Atau silahkan kembali ke',
  successCallWaiter: 'Berhasil memanggil waiter. Mohon tunggu',
  minimumTransaction: 'Minimal transaksi adalah Rp100',
  allowRedirectPayment:
    'Aktifkan popup agar kamu bisa diarahkan langsung ke %{variable}',
  withPaymentMethod: 'dengan %{variable}',
  popupNotShow: '%{variable} kamu tidak terbuka otomatis?',
  openPaymentApp: 'Buka %{variable} sekarang',
  clickNotifOvo: 'Klik notifikasi OVO kamu untuk menyelesaikan pembayaran',
  notifOvoTips:
    'Tips : Ga dapet notifikasi? Coba cek inbox di aplikasi OVO kamu',
  notifOvoIf:
    'Jika notifikasi tidak muncul, kamu bisa buka halaman inbox di aplikasi OVO',
  makeSurePayNominal: 'Pastikan bayar sesuai nominal ya!',
  areYouSureToCancel: 'Apakah kamu yakin akan membatalkan transaksi ini?',
  resendNotification: 'Kirim ulang notifikasi',
  systemBusy: 'Sistem sedang sibuk, silahkan coba lagi',
  chooseTableRow: 'Ketik dan pilih nomor meja',
  full: 'Penuh',
  available: 'Tersedia',
  openTomorrow: 'Buka kembali besok pukul %{time}',
  openOn: 'Buka kembali pukul %{time}',
  or: 'Atau',
  dataNotFound: 'Data tidak ditemukan',
  guestMode: 'Guest Mode',
  youEnterGuestMode: 'Kamu masuk dengan Guest Mode',
  verifyCaptcha: 'Mohon verifikasi terlebih dahulu',
  loginFirst: 'Untuk menggunakan promo, silakan login terlebih dahulu',
  loginHere: 'Login Disini',
  waitCheckout: 'Tunggu sebentar ya, sedang dalam proses checkout',
  youWillRedirect: 'Kamu akan diarahkan ke page selanjutnya',
  sorryCheckoutFailed:
    "Mohon ma'af sepertinya ada kesalahan ketika proses checkout pesananmu",
  needTableMapping: 'Perlu mapping table terlebih dahulu',
  noInternet: 'Tidak ada koneksi Internet',
  noInternetDesc: 'Periksa kembali Internet Anda',
  refresh: 'Refresh',
  areYouSure: 'Udah yakin belum?',
  order_paid: 'Pembayaran Berhasil',
  order_confirm: 'Pesanan Dikonfirmasi',
  order_process: 'Pesanan Diproses',
  order_ready: 'Pesanan Siap',
  order_done: 'Pesanan Selesai',
  order_cancel: 'Pesanan Dibatalkan',
  order_canceled: 'Pesanan Dibatalkan',
  paymentNotFinished: 'Hei, Pembayaranmu belum selesai!',
  pleasePayIn: 'Mohon selesaikan pembayaran dalam :',
  paymentExpired: 'Pembayaran telah kadaluwarsa',
  pleaseReorder: 'Silakan lakukan pemesanan ulang',
  okayGotIt: 'Oke, mengerti',
  // On boarding
  startOrder: 'Mulai Memesan',
  helloWelcome: 'Halo, Selamat Datang di',
  chooseOrder: 'Pilih Pesanan',
  waiting: 'Tunggu',
  showMore: 'Tampilkan lebih banyak (%{total})',
  showLess: 'Tampilkan lebih sedikit',
  tooltipOrder: 'Klik nama tenant untuk melihat detail progress setiap tenant.',
  haventGotTable: 'Belum dapat meja',
  pleaseSelectTable: 'Silakan pilih nomor meja terlebih dahulu',
  check: 'Pantau',
  yourOrder: 'Pesananmu',
  activeOrder: 'Pesanan Aktif',
  thankYouForOrder: 'Terima kasih sudah memesan menggunakan InKanteen!',
  faqTitle: 'Butuh bantuan?',
  faqDesc: 'Bingung saat menggunakan InKanteen? kunjungi',
  faqButton: 'FAQ dan Bantuan',
  helpdesk: 'Bantuan',
  screenShotQRIS: 'Screenshot QRIS',
  openMBanking: 'Buka M-Banking',
  clickPayQR: 'Klik QR Bayar',
  chooseQR: 'Pilih QRIS di galeri',
  buyer: 'Pembeli',
  totalItems: 'Total items',
  faqPageTitle: 'Butuh Bantuan dengan Inkanteen?',
  faqPageSubtitle: 'Kami di Sini untuk Membantu',
  submitComplainButton: 'Masih bingung? Ajukan keluhan',
  thanks: 'Terima kasih!',
  helpdeskSuccessDesc:
    'Keluhan kamu akan segera diproses oleh tim kami, kamu akan dapat pemberitahuan lewat Whatsapp',
  ticketNo: 'Nomor tiket:',
  whatProblem: 'Ada masalah apa?',
  transaction: 'Transaksi',
  paymentProof: 'Bukti pembayaran',
  supportingImage: 'Gambar pendukung',
  uploadImagePaymentDesc:
    'Upload foto/screenshot bukti pembayaran pesanan kamu yang bermasalah',
  uploadImageDesc: 'Upload foto/screenshot',
  uploadImage: 'Upload foto',
  changeImage: 'Ubah foto',
  yourTicket: 'Tiket Kamu',
  pleaseLabel: 'Mohon pilih masalahnya',
  pleasePaymentChannel: 'Mohon pilih metode pembayaran',
  pleaseTransaction: 'Mohon pilih transaksi yang bermasalah',
  pleaseSelectImage: 'Mohon pilih bukti pembayaran',
  pickOrder: 'Pilih pesanan yang bermasalah',
  pleasePickOrder: 'Mohon pilih pesanan yang bermasalah',
  linkVerifDesc: 'Link verifikasi sudah dikirim via WhatsApp ke %{phoneNumber}',
  linkVerifSubDesc:
    'Untuk melanjutkan, silahkan cek WhatsApp kamu dan klik link verifikasi dari InKanteen',
  linkVerifFailed: 'Tidak mendapatkan link?',
  linkVerifResend: 'Kirim ulang',
  linkVerifNeedHelp: 'Butuh bantuan untuk masuk?',
  linkVerifContactUs: 'Hubungi kami',
  chooseStatus: 'Pilih status kamu',
  chooseStatusDesc:
    'Pilihan statusmu akan membantu kami menyediakan promo, konten dan fitur yang paling relevan.',
  copyOTPCode: 'Salin kode OTP',
  welcomeOTPGenerator:
    'Silakan salin kode OTP di bawah untuk dapat melakukan langkah selanjutnya!',
  qrDownloadDesc:
    'Jika Anda menggunakan mode darkmode, silakan download QRIS dan lanjutkan pembayaran di Mbanking Anda',

  qrisStep1: 'Setelah screenshot, buka aplikasi M-Banking',
  qrisStep2: 'Klik logo QR bayar',
  qrisStep3: 'Klik logo gambar untuk upload QRIS',
  qrisStep4: 'Pilih gambar QRIS di galeri untuk bayar',

  privacySentence1:
    'Kebijakan privasi ini (“Kebijakan Privasi”) akan menjelaskan bagaimana PT Kanovasi InMaya Nusantara dan PT InKanteen Technology Solutions dan setiap anak perusahaan serta perusahaan afiliasinya (“Kami”), memperoleh, mengumpulkan, menggunakan, menampilkan, mengumumkan, mengungkapkan, memproses, membukakan akses, menyimpan, mengirim, memberi, mengalihkan, mengolah, mengelola, memusnahkan dan melindungi informasi dan data pribadi (secara bersama-sama, “Pemanfaatan”) yang anda sebagai pengguna (“Anda”) Platform (sebagaimana didefinisikan di bawah) berikan sebagaimana diminta maupun pada saat menggunakan Platform (“Data Pribadi”). Perlu dicatat bahwa Data Pribadi di sini tidak termasuk Data Pribadi yang telah tersedia di domain publik.',
  privacySentence2:
    'Kebijakan Privasi ini merupakan bagian dari Syarat dan Ketentuan Penggunaan (“Ketentuan Penggunaan”) Kami. Penggunaan Platform dan setiap fitur dan/atau layanan yang tersedia dalam Platform (“Layanan”) merupakan bentuk persetujuan anda terhadap Ketentuan Penggunaan dan Kebijakan Privasi tersebut. Oleh karena itu, Anda perlu untuk membaca Kebijakan Privasi ini dengan saksama untuk memastikan bahwa Anda memahaminya sepenuhnya sebelum mendaftar, mengakses dan/atau menggunakan Platform dan Layanan Kami.',
  privacyScopeTitle: 'Lingkup Kebijakan Privasi',
  privacyScope1: 'Kebijakan Privasi ini mengatur Pemanfaatan Data Pribadi',
  privacyScope2:
    'Dengan menggunakan Platform, maka Anda dianggap telah membaca Kebijakan Privasi ini dan menyetujui mekanisme Pemanfaatan Data Pribadi Anda sebagaimana diatur dalam Kebijakan Privasi ini.',
  privacyScope3:
    'Apabila Kami meminta Anda untuk memberikan informasi ketika menggunakan Platform, maka informasi tersebut itu hanya akan digunakan untuk keperluan pemberian Layanan sesuai dengan Kebijakan Privasi ini.',
  privacyScope4:
    'Kami dapat mengubah, menghapus dan untuk menerapkan ketentuan baru dalam Kebijakan Privasi ini. Anda diharapkan untuk memeriksa halaman Kebijakan Privasi ini secara berkala untuk mengetahui perubahan tersebut. Dengan menggunakan Platform setelah terjadinya perubahan tersebut, Anda dianggap telah mengetahui dan menyetujui perubahan-perubahan ketentuan pada Kebijakan Privasi ini.',
  privacyRegistrationTitle: 'Registrasi',
  privacyRegistration1:
    'Anda diharuskan melakukan pendaftaran dalam Platform untuk dapat menggunakan fitur - fitur pada Platform.',
  privacyRegistration2:
    'Untuk melakukan pendaftaran dalam Platform, Anda harus memberikan informasi yang Kami perlukan sebagaimana tercantum pada Poin C (Data Pribadi) di bawah ini.',
  privacyPersonalDataTitle: 'Data Pribadi',
  privacyPersonalData1:
    'Anda mengetahui dan menyetujui bahwa Kami mengumpulkan informasi pribadi yang diberikan Anda saat Anda membuat akun dan profil maupun pada saat menggunakan fitur-fitur yang terdapat dalam Platform.',
  privacyPersonalData2:
    'Informasi mengenai identitas diri yang wajib diisi oleh Anda saat membuat akun di Platform antara lain adalah:',
  privacyPersonalData2Sub1:
    'Nama lengkap sesuai kartu identitas yang berlaku (KTP atau Paspor); dan',
  privacyPersonalData2Sub2:
    'Nomor telepon genggam, baik yang terhubung dengan WhatsApp ataupun tidak.',
  privacyPersonalData3:
    'Anda dapat mengaktifkan atau menonaktifkan layanan pengenalan lokasi saat Anda menggunakan Platform. ',
  privacyPersonalData4:
    'Apabila diperlukan, Kami dapat melakukan verifikasi langsung kepada Anda tentang data diri yang telah Anda sampaikan melalui Platform.',
  privacyPersonalData5: 'Informasi yang Anda berikan adalah akurat dan benar.',
  privacyVisitorDataTitle: 'Data Pengunjung',
  privacyVisitorDataSentence:
    'Anda mengetahui dan menyetujui bahwa Platform akan mengumpulkan informasi tentang penggunaan, seperti (namun tidak terbatas pada) jumlah kunjungan, sumber pengunjung dan aktivitas pengunjung. Data ini kami kumpulkan dan kami gunakan dengan tujuan meningkatkan kepuasan Anda serta layanan Kami.',
  privacyVisitorData1:
    'Kami dan Penyedia Layanan dapat melakukan Pemanfaatan Data Pribadi Anda untuk kegiatan bisnis dan operasional sesuai dengan tujuan diberikannya Data Pribadi tersebut, termasuk untuk:',
  privacyVisitorData1Sub1:
    'Menyediakan informasi kepada Anda dan Penyedia Layanan sesuai permintaan Anda berkaitan dengan Layanan Kami.',
  privacyVisitorData1Sub2:
    'Mengembangkan, meningkatkan dan menyediakan produk dan layanan yang sesuai dengan kebutuhan Anda.',
  privacyVisitorData1Sub3: 'Menanggapi pertanyaan, komentar dan masukan Anda.',
  privacyVisitorData1Sub4:
    'Memberikan informasi/mengirimkan e-mail dan/atau SMS berisi promosi secara berkala kepada Anda bila terdapat suatu penawaran dan/atau promosi atas produk-produk baru, penawaran khusus, atau informasi lain yang menurut Kami diperlukan oleh Anda.',
  privacyVisitorData1Sub5:
    'Menggunakan dan mengungkapkan informasi ini jika diperlukan bagi tinjauan medis, jasa hukum, dan audit, meliputi penipuan dan deteksi penyalahgunaan dan program penyesuaian, serta perencanaan dan manajemen bisnis.',
  privacyVisitorData2:
    'Kami menjamin bahwa data dan informasi yang diberikan Anda bersifat rahasia dan tidak akan disebarluaskan kecuali untuk hal-hal yang telah dicantumkan dan Anda setujui dalam Kebijakan Privasi ini. Untuk mencegah akses tidak sah, Kami melakukan tindakan pengamanan fisik, elektronik, dan prosedur manajerial untuk melindungi informasi Anda.',
  privacyVisitorData3:
    'Informasi kesehatan Anda hanya akan digunakan sehubungan dengan pelayanan Platform dan hal-hal yang telah dicantumkan dan Anda setujui dalam Kebijakan Privasi ini dan Kami hanya akan mengumpulkan informasi yang dibutuhkan untuk menjalankan pelayanan dalam Platform.',
  privacyOtherPlatformsTitle: 'Koneksi Anda Ke Platform Lain',
  privacyOtherPlatformsSentence:
    'Platform dapat memuat tautan menuju platform milik pihak ketiga (“Platform Pihak Ketiga”) dan konten pihak ketiga (“Konten Pihak Ketiga”). Untuk keamanan Anda, Anda perlu mempelajari dan membaca dengan hati-hati kebijakan penanganan informasi pribadi yang berlaku di Platform Pihak Ketiga dan/atau Konten Pihak Ketiga.',
  privacyLawTitle: 'Hukum Yang Berlaku',
  privacyLawSentence:
    'Kebijakan Privasi ini diatur berdasarkan hukum Negara Republik Indonesia dan Anda diwajibkan tunduk kepada semua peraturan yang berlaku di Republik Indonesia.',
  privacySecurityTitle: 'Upaya Pengamanan',
  privacySecurity1:
    'Kami akan berupaya memastikan bahwa informasi yang Anda berikan kepada Kami aman dan tidak dapat digunakan oleh pihak-pihak yang tidak bertanggung jawab. Untuk keamanan data Anda, Kami sangat menyarankan agar Anda selalu memperbarui Platform dan perangkat lunak anda serta tidak mengungkapkan kata sandi anda kepada pihak manapun.',
  privacySecurity2:
    'Anda dengan ini setuju bahwa Kami dapat menyimpan Data Pribadi pada server yang terletak di pusat data yang ditunjuk oleh Kami. Pemanfaatan Data Pribadi sehubungan dengan penggunaan Platform akan terus diatur oleh Kebijakan Privasi ini sesuai dengan peraturan perundangan-undangan yang berlaku di Republik Indonesia.',
  privacyContactTitle: 'Pertanyaan, keluhan dan kontak',
  privacyContactSentence:
    'Jika Anda memiliki pertanyaan tentang kebijakan kerahasiaan ini, peraturan-peraturan Kami, hak Anda, dan hubungan Anda dengan Platform, silakan mengirim surat elektronik Kami di:',
}

// export default id
